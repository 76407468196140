import React from "react";
import Homesec3 from "../components/homepage/homesec3";
import Homesec4 from "../components/homepage/h5CustomLink";
import Homesec5 from "../components/homepage/h4LinkManagement";
import Layout from "../components/layout/index";
import Seo from "../components/seo";

const index = () => {
  return (
    <Layout>
      <Seo
        slug="/features/"
        title="Custom Link Shortener | Custom URL Generator | Teenyfy Feature "
        description="Teenyfy is a link management platfrom that offering custom url shortener, custom name url shortener and more feature. Sign up with your mail & enjoy services. "
      />
      <Homesec3 isPage={true} />
      <Homesec4 />
      <Homesec5 />
    </Layout>
  );
};

export default index;
